<template>
  <div v-if="sessionsFilters" class="StatSessionFilters">
    <DateRangePicker
      :label="$lng.__('lk_webmaster_main', 'Period')"
      ref="picker"
      opens="right"
      :locale-data="{
        firstDay: 1,
        format: 'dd.mm.yyyy',
        daysOfWeek: $lng
          .__('lk_webmaster_main', 'Sun,Mon,Tue,Wed,Thu,Fri,Sat')
          .split(','),
        monthNames: $lng
          .__(
            'lk_webmaster_main',
            'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec'
          )
          .split(','),
      }"
      :singleDatePicker="false"
      :timePicker="false"
      :showDropdowns="true"
      :autoApply="true"
      :dateRange="filters.dateRange"
      v-model="filters.dateRange"
      @update="setDateRange"
      :linkedCalendars="false"
    >
    </DateRangePicker>

    <FormSelect
      :label="$lng.__('lk_webmaster_main', 'Group by time')"
      :placeholder="$lng.__('lk_webmaster_main', 'Select item')"
      :showResetOption="false"
      :options="filterByTimeOptions"
      v-model:selected="filters.groupByTime"
    ></FormSelect>

    <FormSelect
      :label="$lng.__('lk_webmaster_main', 'Group by')"
      :placeholder="$lng.__('lk_webmaster_main', 'Select item')"
      :showResetOption="true"
      :options="filterGroupByOneOptions"
      v-model:selected="filters.groupBy"
      @changed="
        filters.groupBy2 = false;
        filters.groupBy3 = false;
      "
    ></FormSelect>

    <FormSelect
      :label="$lng.__('lk_webmaster_main', 'Group by')"
      :placeholder="$lng.__('lk_webmaster_main', 'Select item')"
      :showResetOption="true"
      :options="filterGroupByTwoOptions"
      v-model:selected="filters.groupBy2"
      @changed="filters.groupBy3 = false"
    ></FormSelect>

    <FormSelect
      :label="$lng.__('lk_webmaster_main', 'Group by')"
      :placeholder="$lng.__('lk_webmaster_main', 'Select item')"
      :showResetOption="true"
      :options="filterGroupByThreeOptions"
      v-model:selected="filters.groupBy3"
    ></FormSelect>

    <FormSelectMulti
      :label="$lng.__('lk_webmaster_main', 'Offer')"
      :placeholder="$lng.__('lk_webmaster_main', 'Choose an offer')"
      v-model:selected="filters.offer"
      :borderless="false"
      :multiple="false"
      :nested="false"
      key="filterOffer"
      :options="offerOptions"
    >
    </FormSelectMulti>

    <FormSelectMulti
      :label="$lng.__('lk_webmaster_main', 'Geo')"
      :placeholder="$lng.__('lk_webmaster_main', 'Select country')"
      v-model:selected="filters.country"
      :borderless="false"
      :multiple="false"
      :nested="false"
      key="filterCountry"
      :options="countryOptions"
    >
    </FormSelectMulti>

    <FormSelectMulti
      :label="$lng.__('lk_webmaster_main', 'Stream')"
      :placeholder="$lng.__('lk_webmaster_main', 'Select stream')"
      v-model:selected="filters.stream"
      :borderless="false"
      :multiple="false"
      :nested="false"
      key="filterStream"
      :options="streamOptions"
    >
    </FormSelectMulti>

    <FormSelectMulti
      :label="$lng.__('lk_webmaster_main', 'Showcase type')"
      :placeholder="$lng.__('lk_webmaster_main', 'Select item')"
      v-model:selected="filters.shopwindow_type"
      :borderless="false"
      :multiple="false"
      :nested="false"
      key="filterShopWindowType"
      :options="shopwindowTypeOptions"
    >
    </FormSelectMulti>

    <FormSelectMultiUTM
      label="Utm Content"
      :placeholder="$lng.__('lk_webmaster_main', 'Select UTM')"
      v-model:selected="this.utmContent"
      @input="getUTM"
      :borderless="false"
      :multiple="false"
      :nested="false"
      key="filterUtmContent"
      :options="this.utm_content"
    >
    </FormSelectMultiUTM>

    <FormSelectMultiUTM
      label="Utm Medium"
      :placeholder="$lng.__('lk_webmaster_main', 'Select UTM')"
      v-model:selected="this.utmMedium"
      @input="getUTM"
      :borderless="false"
      :multiple="false"
      :nested="false"
      key="filterUtmMedium"
      :options="this.utm_medium"
    >
    </FormSelectMultiUTM>

    <FormSelectMultiUTM
      label="Utm Term"
      :placeholder="$lng.__('lk_webmaster_main', 'Select UTM')"
      v-model:selected="this.utmTerm"
      @input="getUTM"
      :borderless="false"
      :multiple="false"
      :nested="false"
      key="filterUtmTerm"
      :options="this.utm_term"
    >
    </FormSelectMultiUTM>

    <FormSelectMultiUTM
      label="Utm Campaign"
      :placeholder="$lng.__('lk_webmaster_main', 'Select UTM')"
      v-model:selected="this.utmCampaign"
      @input="getUTM"
      :borderless="false"
      :nested="false"
      :multiple="false"
      key="filterUtmCampaign"
      :options="this.utm_campaign"
    >
    </FormSelectMultiUTM>

    <div class="StatSessionFilters__reset">
      <button
        v-if="isResetFiltersVisible"
        @click="resetFilters()"
        class="wv-btn--green wv-btn--text wv-btn--block"
      >
        {{ $lng.__('lk_webmaster_main', 'Reset') }}
      </button>
    </div>
    <div class="StatSessionFilters__reset" v-if="tableStatisticsColumns > 0">
      <button class="wv-btn--green wv-btn--text wv-btn--block" @click="showCustomizeTable()">
        <SvgUse :width="16" :height="16" id="btnCustomizeTable"></SvgUse>
        <p>
          {{ $lng.__('lk_webmaster_main', 'Customize table') }}
        </p>
      </button>
    </div>
  </div>
</template>

<script>
  import FormSelectMultiUTM from './FormSelectMultiUTM.vue';

  export default {
    name: 'StatSessionFilters',
    components: { FormSelectMultiUTM },
    props: ['filters', 'tableStatisticsColumns'],
    data() {
      return {
        utm_content: [],
        utm_campaign: [],
        utm_medium: [],
        utm_term: [],
        utmContent: '',
        utmCampaign: '',
        utmMedium: '',
        utmTerm: '',
      };
    },
    emits: ['update:filters', 'customizeTable'],
    computed: {
      sessionsFilters() {
        return this.$store.state.webmaster.sessionsFilters;
      },

      // utmTermOptions() {
      //   return this.convertArrayToOptions(this.sessionsFilters.utm_term);
      // },
      // utmCampaignOptions() {
      //   return this.convertArrayToOptions(this.sessionsFilters.utm_campaign);
      // },
      // utmMediumOptions() {
      //   return this.convertArrayToOptions(this.sessionsFilters.utm_medium);
      // },
      // utmContentOptions() {
      //   return this.convertArrayToOptions(this.sessionsFilters.utm_content);
      // },

      streamOptions() {
        return this.sessionsFilters.streams
          ? collect([
              ...this.sessionsFilters.streams,
              {
                id: null,
                name: this.$lng.__('lk_webmaster_main', 'No stream'),
              },
            ])
              .sortBy((option) => {
                return option.id === null ? 0 : option.id;
              })
              .all()
          : [];
      },
      countryOptions() {
        return this.sessionsFilters.country
          ? collect(this.sessionsFilters.country)
              .sortBy((option) => {
                return option.id === null ? 0 : option.id;
              })
              .all()
          : [];
      },
      offerOptions() {
        return this.sessionsFilters.offers ? this.sessionsFilters.offers : [];
      },
      isResetFiltersVisible() {
        return (
          this.utmContent.length !== '' ||
          this.utmCampaign.length !== '' ||
          this.utmMedium.length !== '' ||
          this.utmTerm.length !== '' ||
          this.filters.stream.length ||
          this.filters.shopwindow_type.length ||
          this.filters.groupBy ||
          this.filters.groupBy2 ||
          this.filters.groupByTime !== 'all'
        );
      },
      filterByTimeOptions() {
        return [
          { id: 'days', name: this.$lng.__('lk_webmaster_main', 'Days') },
          { id: 'weeks', name: this.$lng.__('lk_webmaster_main', 'Weeks') },
          { id: 'all', name: this.$lng.__('lk_webmaster_main', 'All time') },
        ];
      },
      shopwindowTypeOptions() {
        return [
          { id: 'comebacker', name: 'Comebacker' },
          { id: 'success', name: 'Success Page' },
        ];
      },
      filterGroupByOptions() {
        return [
          { id: 'offer', name: this.$lng.__('lk_webmaster_main', 'Offer') },
          { id: 'stream', name: this.$lng.__('lk_webmaster_main', 'Stream') },
          { id: 'country', name: this.$lng.__('lk_webmaster_main', 'Geo') },
          { id: 'utm_medium', name: 'UTM Medium' },
          { id: 'utm_content', name: 'UTM Content' },
          { id: 'utm_term', name: 'UTM Term' },
          { id: 'utm_campaign', name: 'UTM Campaign' },
          { id: 'shopwindow', name: 'Showcase' },
        ];
      },
      filterGroupByOneOptions() {
        return this.filterGroupByOptions.filter((option) => {
          return (
            this.filters.groupBy2 !== option.id &&
            this.filters.groupBy3 !== option.id
          );
        });
      },
      filterGroupByTwoOptions() {
        return this.filterGroupByOptions.filter((option) => {
          return (
            this.filters.groupBy !== option.id &&
            this.filters.groupBy3 !== option.id
          );
        });
      },
      filterGroupByThreeOptions() {
        return this.filterGroupByOptions.filter((option) => {
          return (
            this.filters.groupBy !== option.id &&
            this.filters.groupBy2 !== option.id
          );
        });
      },
    },
    methods: {
      convertArrayToOptions(array) {
        return collect(array)
          .flatten()
          .unique()
          .map((item) => {
            return { id: item, name: item };
          })
          .all();
      },
      resetFilters() {
        this.utmContent = '';
        this.utmCampaign = '';
        this.utmMedium = '';
        this.utmTerm = '';

        this.$emit('update:filters', {
          ...this.filters,
          dateRange: { startDate: new Date(), endDate: new Date() },
          utm_content: [],
          utm_campaign: [],
          utm_medium: [],
          utm_term: [],
          stream: [],
          groupBy: false,
          groupBy2: false,
          groupBy3: false,
          groupByTime: 'all',
          country: [],
          offer: [],
          shopwindow_type: [],
        });
      },
      setDateRange(dateRange) {
        this.$emit('update:filters', {
          ...this.filters,
          dateRange: {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          },
        });
      },
      showCustomizeTable() {
        this.$emit('customizeTable');
      },
      getUTM({ utm_name = '', text = '' }) {
        let from =
          this.filters.dateRange.startDate.getFormattedDate() + ' 00:00:00';
        let to =
          this.filters.dateRange.endDate.getFormattedDate() + ' 23:59:59';
        let model = utm_name.charAt(0).toLowerCase();
        utm_name = utm_name.toLowerCase().replace(' ', '_');

        if (utm_name !== '') {
          WV.Api.getStatUTM(from, to, utm_name, text)
            .then((response) => {
              this[`${utm_name}`] = this.convertArrayToOptions(response);
              this[`${model}`] = this.convertArrayToOptions(response);
            })
            .catch((error) => {
              console.error(`UTM API error: ${error}`);
            });
        }
      },
    },
    watch: {
      filters: {
        deep: true,
        handler(val, oldVal) {
          if (
            oldVal.dateRange.startDate != val.dateRange.startDate ||
            oldVal.dateRange.endDate != val.dateRange.endDate
          ) {
            this.getUTM({ utm_name: 'utm_content' });
            this.getUTM({ utm_name: 'utm_campaign' });
            this.getUTM({ utm_name: 'utm_medium' });
            this.getUTM({ utm_name: 'utm_term' });
          }
        },
      },
      utmContent(val, oldVal) {
        this.$emit('update:filters', {
          ...this.filters,
          utm_content: val,
        });
      },
      utmCampaign(val, oldVal) {
        this.$emit('update:filters', {
          ...this.filters,
          utm_campaign: val,
        });
      },
      utmMedium(val, oldVal) {
        this.$emit('update:filters', {
          ...this.filters,
          utm_medium: val,
        });
      },
      utmTerm(val, oldVal) {
        this.$emit('update:filters', {
          ...this.filters,
          utm_term: val,
        });
      },
    },
    mounted() {
      this.getUTM({ utm_name: 'utm_content' });
      this.getUTM({ utm_name: 'utm_campaign' });
      this.getUTM({ utm_name: 'utm_medium' });
      this.getUTM({ utm_name: 'utm_term' });
    },
  };
</script>

<style lang="scss">
  @import '../../../scss/utils/mixins.scss';
  @import '../../../scss/utils/variables.scss';

  .StatSessionFilters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    & > div {
      flex: none;
      margin-right: 15px;
      margin-bottom: 40px;
      @include break(md) {
        width: calc(50% - 15px) !important;
        &:nth(2n) {
          margin-right: 0;
        }
      }
      @include break(lg) {
        width: calc(33.33% - 15px) !important;
        &:nth(3n) {
          margin-right: 0;
        }
      }
      @include break(xl) {
        width: calc(25% - 15px) !important;
        &:nth(4n) {
          margin-right: 0;
        }
      }
    }
  }
</style>
